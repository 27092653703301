import React from 'react';
import { Helmet } from 'react-helmet';

import 'normalize.css'
import '../styles/index.css';

import logo from '../images/icon/logo/white.svg'
import appStore from '../images/app-store.svg'

import Copyright from '../components/Copyright'

function Index({ location }) {
  return (
    <div id="home">
      <Helmet>
        <title>REGYM - light and powerful application for Personal Trainers</title>
        <meta name="title" content="REGYM - light and powerful application for Personal Trainers" />
        <meta name="description" content="" />

        <meta property="og:type" content="website"/>
        <meta property="og:url" content={`${location.origin}/invite`} />
        <meta property="og:title" content="REGYM - light and powerful application for Personal Trainers"/>
        <meta property="og:description" content=""/>
        <meta property="og:image" content={`promotion.png`} />

        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content={`${location.origin}/invite`}  />
        <meta property="twitter:title" content="REGYM - light and powerful application for Personal Trainers"/>
        <meta property="twitter:description" content=""/>
        <meta property="twitter:image" content={`promotion.png`} />
      </Helmet>
      <div className="landing-bg">
        <img src={logo} alt="proFIT" className="logo"/>
      </div>
      <footer>
        <div className="coming">
          <span className="title">
            COMING SOON IN
          </span>
          <img src={appStore} alt="App Store" className="app-store"/>
        </div>
        <Copyright />
      </footer>
    </div>
  );
}

export default Index;
